import React, {useState, useEffect} from "react"
import Animation from "./Animation"
import ShortVideo from "./ShortVideo"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import Masonry from 'react-masonry-css'


const ContentContainer = styled.div`
  padding: 56px 17px 136px;
  @media(min-width: ${breakpoints.md}){
    padding-top: 103px;
    padding-bottom: 224px;
  }
`
const MasonryWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
    .my-masonry-grid {
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }

  .my-masonry-grid_column > div { 
    margin-bottom: 30px;
  }

`

const breakpointColumnsObj = {
  default: 2,
  600: 1
};

const AnimationPage = ({blocks}) => {
  const [unmutedVideo, setUnmutedVideo] = useState(null)
  const [width, setWidth] = useState(0)

  const getWindowWidth = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      getWindowWidth()
      window.addEventListener("resize", getWindowWidth)
    }
    return () => {
      window.removeEventListener("resize", getWindowWidth)
    }
  })

  return (
        <ContentContainer>
          <MasonryWrapper>
            <Masonry 
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column">
              {blocks.map((block, index) => {
                  if(block.autoplayEnabled && width >= 600) {
                    return <ShortVideo block={block} key={index} id={index} />
                  } else {
                    return <Animation key={index} block={block} unmutedVideo={unmutedVideo} setUnmutedVideo={setUnmutedVideo} videoIndex={index} isMobile={width < 600} />
                  }
                })}
            </Masonry>
          </MasonryWrapper>
        </ContentContainer>
  )
}

export default AnimationPage
