import React, { useRef, useState, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import PauseIcon from "../../resources/img/ui/pause.svg"
import PlayIcon from "../../resources/img/ui/play.svg"
import MutedIcon from "../../resources/img/ui/muted.svg"
import UnmutedIcon from "../../resources/img/ui/unmuted.svg"
import useOnScreen from "../../helpers/intersection"

const Container = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: fit-content;
`
const ControlsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  pointer-events: none;
`
const StyledVideo = styled.video`
  cursor: pointer;
  outline: none;
  width: 100%;
  height: auto;
  /* object-fit: contain; */
`

const SoundButtonContainer = styled.div`
  position: absolute;
  bottom: 44px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`
const StyledIcon = styled.img`
  opacity: ${(props) => (props.visible ? "1" : "0")};
  transition: opacity 0.4s;
  display: block;
`

const SoundIcon = styled(StyledIcon)`
  width: 9.5%;
  height: auto;
  margin: 0 auto;
`
const ControlIcon = styled(StyledIcon)`
  width: ${(props) => (props.isPlaying ? "13.5%" : "14.3%")};
  height: auto;
`
const VideoContainer = styled.div``

const Animation = ({ block, unmutedVideo, setUnmutedVideo, videoIndex, isMobile }) => {
  const { video, videoPreview, thumbnail } = block

  const [isPlaying, setIsPlaying] = useState(false)
  const [showControls, setShowControls] = useState(false)
  const [preview, setPreview] = useState(true)
  const [isPreviewVideo, setIsPreviewVideo] = useState(
    videoPreview?.file?.url ? true : false
  )

  const videoRef = useRef()
  const preVideoRef = useRef()
  let videoVisible = useOnScreen(videoRef)

 
  useEffect(() => {
    const video = videoRef.current
    if (video) {
      if (!preview && !isMobile) {
        if (videoVisible) {
          video.play()
          setIsPlaying(true)          
        } else {
          video.pause()
            setUnmutedVideo(null)
            setIsPlaying(false)
        }
      }
    }
  }, [videoVisible])


  useEffect(() => {
    const video = videoRef.current
    if (video) {
      video.muted = unmutedVideo !== videoIndex
    }
  }, [unmutedVideo])

  let iconImage
  let soundImage

  if (!isPlaying) {
    iconImage = PlayIcon
  } else if (isPlaying) {
    iconImage = PauseIcon
  }

  if (unmutedVideo !== videoIndex) {
    soundImage = MutedIcon
  } else {
    soundImage = UnmutedIcon
  }

  const toggleVideo = () => {
    if (preview) {
      startFullVideo()
    } else {
      const videoEl = videoRef.current
      if (videoEl) {
        if (isPlaying ) {
          videoEl.pause()
        } else {
          videoEl.play()
        }
        setIsPlaying(!isPlaying)
      }
      if(isMobile) {
        setShowControls(true)
      }
    }
  }

  const toggleSound = () => {
    if (preview) {
      startFullVideo()
    } else {
      if (unmutedVideo === videoIndex) {
        setUnmutedVideo(null)
      } else {
        setUnmutedVideo(videoIndex)
      }
    }
  }

  useEffect(() => {
    if(!preview ) {
      setIsPlaying(true)
      setShowControls(false)
    }else{
      setShowControls(true)
    } 
  }, [preview])

  const startFullVideo = () => {
    setPreview(false)
    
    if(isPreviewVideo) {
      const preVideo = preVideoRef.current
      if(preVideo) {
        const videoContainer = preVideoRef.current.parentElement
        videoContainer.style.height = `${preVideo.offsetHeight}px`
        setTimeout(() => {
          videoContainer.style.height = "auto"
        }, 400)
      }
    }else {
      if(videoRef && videoRef.current) {
        videoRef.current.play()
      }
    }
    setIsPreviewVideo(false)
    setUnmutedVideo(videoIndex)
  }

  const toggleControls = (action) => {
    if (!preview && !isMobile) {
      setShowControls(action)
    }
  }

  useEffect(()=> {
    if(isMobile < 600 && showControls && !preview && isPlaying) {
      setTimeout(() => {
        setShowControls(false)
    }, 2000);
    }
  },[showControls, isPlaying])

  return (
    <Container
      onMouseEnter={() => {
        toggleControls(true)
      }}
      onMouseLeave={() => {
        toggleControls(false)
      }}
    >
      <VideoContainer>
        {preview && isPreviewVideo && !isMobile ? (
          <StyledVideo
            muted
            loop
            playsInline
            controls={false}
            preload="metadata"
            aria-label="video"
            ref={preVideoRef}
            type="video/mp4"
            onClick={startFullVideo}
            src={videoPreview.file.url}
            autoPlay={true}
            poster={thumbnail?.file?.url}
          ></StyledVideo>
        ) : (
          <StyledVideo
            loop
            playsInline
            controls={false}
            preload="metadata"
            aria-label="video"
            ref={videoRef}
            type="video/mp4"
            onClick={toggleVideo}
            src={video.file.url}
            autoPlay={(isPreviewVideo && !isMobile ) ? true : false}
            poster={thumbnail?.file?.url}
          ></StyledVideo>
        )}
      </VideoContainer>
      <ControlsContainer >
        <ControlIcon
          src={iconImage}
          alt="control"
          visible={showControls ? 1 : 0}
          isPlaying={isPlaying}
        />
      </ControlsContainer>
      <SoundButtonContainer onClick={toggleSound}>
        <SoundIcon
          src={soundImage}
          alt="sound"
          visible={showControls ? 1 : 0}
        />
      </SoundButtonContainer>
    </Container>
  )
}

export default Animation
