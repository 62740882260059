import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import CoreContainer from "../components/Core/CoreContainer"
import { graphql } from "gatsby"
import Animations from "../components/Animation/AnimationPage"


const AnimationPage = (props) => {
  const path = props.location.pathname
  const page = props.data.allContentfulAnimationPage.edges[0].node
  let blocks = page.animationBlocks


  return (
    <Layout>
      <SEO title={page.metaTitle}
           description={page.metaDescription}
           fullTitle={false}
           path={path}/>
      <CoreContainer>
        <Animations blocks={blocks} />
      </CoreContainer>
    </Layout>
  )
}

export default AnimationPage

export const pageQuery = graphql`
  query AnimationpageQuery {
    allContentfulAnimationPage {
      edges {
        node {
          metaTitle
          metaDescription
          animationBlocks {
            entryTitle
            autoplayEnabled
            halfWidth
            halfWidthVideoPosition
            thumbnail {
              file {
                url
              }
            }
            videoPreview {
                file {
                    contentType
                    fileName
                    url
                }
            }
            video {
              file {
                contentType
                fileName
                url
              }
            }
          }
        }
      }
    }
  }
`
