import React, {useState, useEffect} from "react";

export default function useOnScreen(ref) {

    const [isIntersecting, setIntersecting] = useState(false)
    let observer;
    if (typeof window != "undefined") {
      observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting)
      )
    }
    useEffect(() => {
      if(observer && ref.current) {
        observer.observe(ref.current)
      }
      // Remove the observer as soon as the component is unmounted
      return () => { 
        if(observer) {
          observer.disconnect() 
        }
      }
    }, [ref.current])
  
    return isIntersecting
  }