import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"
import useOnScreen from "../../helpers/intersection"
// import { useInView } from 'react-intersection-observer';

const Container = styled.div`
  position: relative;
  cursor: normal;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: ${props => props.isRight ? "flex-end" : "flex-start"};
`

const StyledVideo = styled.video`
  outline: none;
  width: ${props => props.half ? "50%" : "100%"};
  height: auto;
  pointer-events: none;
`


const ShortVideo = ({ block}) => {
  const { video, halfWidthVideoPosition, halfWidth } = block

  const videoRef = useRef(null)

  return (
    <Container isRight={halfWidthVideoPosition}> 
      <StyledVideo
        muted
        loop
        playsInline
        controls={false}
        preload="metadata"
        aria-label="video"
        ref={videoRef}
        type="video/mp4"
        src={video.file.url}
        autoPlay={true}
        half={halfWidth}
        
      >
        <source src={video.file.url} type="video/mp4"/>
                    Your browser does not support the video tag.
      </StyledVideo>
    </Container>
  )
}

export default ShortVideo
